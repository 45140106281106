body {
  margin: 0;
}

.StripeElement {
  border-bottom: 1px solid #C0C0C0;
  padding-bottom: 8px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
