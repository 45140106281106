@font-face {
  font-family: 'Muli-Regular';
  src: url('fonts/muli-regular-webfont.woff2') format('woff2'),
  url('fonts/muli-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Poppins-Semibold';
  src: url('fonts/poppins-semibold-webfont.woff2') format('woff2'),
  url('fonts/poppins-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('fonts/poppins-regular-webfont.woff2') format('woff2'),
  url('fonts/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}